<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.keys") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="keys"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="stamp"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/keys/${id}`)"
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/keys/create')"
              :icon="PlusIcon"
              id="header-key-create-button"
              label="create_key"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #cell(loaned)="{ item }">
          <div class="flex items-center">
            <span>{{ item.loaned ? t("labels.yes") : t("labels.no") }}</span>
          </div>
        </template>
        <template #cell(estate)="{ value }">
          <span>{{ value ? value.name : null }}</span>
        </template>
        <template #cell(address)="{ item }">
          <span>{{ item.estate ? item.estate.address : null }}</span>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { KeyIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";

export default {
  components: { Breadcrumb, CustomTable, FormButton, KeyIcon, PlusIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const fields = ref([
      {
        key: "stamp",
        label: "stamp",
      },
      {
        key: "loaned",
        label: "loaned",
      },
      {
        key: "estate",
        label: "estate_name",
      },
      {
        key: "address",
        label: "address",
      },
    ]);
    const searchFields = ref(["stamp", "estate.name", "estate.address"]);
    const sortFields = ref(["stamp"]);

    const company = computed(() => store.state.company.company);
    const keys = computed(() => store.state.key.keys);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.key.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    onMounted(() => {
      store.dispatch("key/getKeys", user.value.companyId);
    });

    return {
      company,
      fields,
      keys,
      PlusIcon,
      router,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
